import React from 'react';
import { Create, Datagrid, Edit, EditButton, Toolbar, List, Show, ReferenceField, ShowButton, FunctionField, SimpleForm, SimpleShowLayout, ReferenceInput, SelectInput } from 'react-admin';
import { CustomUpdateButton } from '../../components/Button';
import { ListActions } from '../../components/List';
import CustomPagination from '../../components/Pagination';
import { fieldsToInputs, resourcesPropertiesToFields } from "../../helpers/fieldTransformer";

const ReportPointEditToolbar = (props) => {
  const { record, redirect, resource } = props;
  return (
    <Toolbar {...props} >
      <CustomUpdateButton record={record} redirectTo={redirect} resource={resource} />
    </Toolbar>
  );
}

export const ReportPointList = (props) => {
  const { options: { api, resource } } = props;
  const fields = resourcesPropertiesToFields(api, resource, ['title', 'businessSegment', 'favorable']);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      pagination={<CustomPagination />}
      perPage={50} actions={<ListActions />}
    >
      <Datagrid>
        <ReferenceField source="question" reference="questions">
          <FunctionField render={record => `Q${record.identifier} ${record.title}`} />
        </ReferenceField>
        {fields.map(field =>
          field
        )}
        <EditButton basePath="/report_points" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const ReportPointCreate = (props) => {
  const { options: { api, resource } } = props;

  const fields = fieldsToInputs(api, resource, ['title', 'favorable', 'businessSegment']);

  return (
    <Create {...props}>
      <SimpleForm>
        {fields.map(field =>
          field
        )}
        <ReferenceInput source="question" reference="questions" perPage={100}>
          <SelectInput optionText={(record) => {
            if (record.identifier) {
              return `Q${record.identifier} ${record.title}`;
            }
            return `${record.title}`;
          }} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
};

export const ReportPointEdit = (props) => {
  const { options: { api, resource } } = props;

  const fields = fieldsToInputs(api, resource, ['title', 'favorable', 'businessSegment']);

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<ReportPointEditToolbar />}>
        {fields.map(field =>
          field
        )}
        <ReferenceInput source="question" reference="questions" perPage={100}>
          <SelectInput optionText={(record) => {
            if (record.identifier) {
              return `Q${record.identifier} ${record.title}`;
            }
            return `${record.title}`;
          }} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
};

export const ReportPointShow = (props) => {
  const { options: { api, resource } } = props;
  const fields = resourcesPropertiesToFields(api, resource);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        {fields.map(field =>
          field
        )}
      </SimpleShowLayout>
    </Show>
  )
};
