import React from 'react';
import { Create, SimpleForm, ShowButton, EditButton } from 'react-admin';
import { List, Datagrid, Edit } from 'react-admin';
import {fieldsToInputs, resourcesPropertiesToFields} from "../../helpers/fieldTransformer";
import { ListActions } from '../../components/List';

export const BusinessSegmentVariantList = (props) => {

  const {options: { api, resource }} = props;
  const fields = resourcesPropertiesToFields(api, resource);

  return (
    <List {...props} bulkActionButtons={false} exporter={false} actions={<ListActions />}>
      <Datagrid>
        {fields.map(field =>
          field
        )}
        <EditButton basePath="/business_segment_variants" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const redirect = (basePath, id, data) => `/questions/${encodeURIComponent(data.question)}/2`;

export const BusinessSegmentVariantCreate = (props) => {
    const {options: { api, resource }} = props;
    const fields = fieldsToInputs(api, resource);

    return (
        <Create { ...props }>
            <SimpleForm redirect={redirect}>
                {fields.map(field =>
                    React.cloneElement(field, {
                        fullWidth: true,
                        resource
                    })
                )}
            </SimpleForm>
        </Create>
    )
};

export const BusinessSegmentVariantEdit = (props) => {
    const {options: { api, resource }} = props;
    const fields = fieldsToInputs(api, resource);

    return (
        <Edit { ...props }>
            <SimpleForm redirect={redirect}>
                {fields.map(field =>
                    React.cloneElement(field, {
                        fullWidth: true,
                        resource
                    })
                )}
            </SimpleForm>
        </Edit>
    )
};
