import React from 'react';
import { Create, SimpleForm, ShowButton, EditButton } from 'react-admin';
import { List, Datagrid, Edit } from 'react-admin';
import {fieldsToInputs, resourcesPropertiesToFields} from "../../helpers/fieldTransformer";
import { ListActions } from '../../components/List';

export const BusinessSegmentList = (props) => {

  const {options: { api, resource }} = props;
  const fields = resourcesPropertiesToFields(api, resource, ['code', 'name', 'description', 'picto']);

  return (
    <List {...props} bulkActionButtons={false} exporter={false} actions={<ListActions />}>
      <Datagrid>
        {fields.map(field =>
          field
        )}
        <EditButton basePath="/business_segments" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const BusinessSegmentCreate = (props) => {
    const {options: { api, resource }} = props;
    const fields = fieldsToInputs(api, resource);

    return (
        <Create { ...props }>
            <SimpleForm>
                {fields.map(field =>
                    React.cloneElement(field, {
                        fullWidth: true,
                        resource
                    })
                )}
            </SimpleForm>
        </Create>
    )
};

export const BusinessSegmentEdit = (props) => {
    const {options: { api, resource }} = props;
    const fields = fieldsToInputs(api, resource);

    return (
        <Edit { ...props }>
            <SimpleForm>
                {fields.map(field =>
                    React.cloneElement(field, {
                        fullWidth: true,
                        resource
                    })
                )}
            </SimpleForm>
        </Edit>
    )
};
