
const API = process.env.REACT_APP_API_ENTRYPOINT;

export const apiCommunication = (method, destination) => {
  const fetchHeaders = { 'Authorization': `Bearer ${window.localStorage.getItem('token')}` };

  return fetch(`${API}${destination}`, {
    method: method,
    headers: new Headers({
      'Content-Type': 'application/json',
      ...fetchHeaders
    })
  }).then(
    res => res.json()
  ).then(
    (data) => { return data; }
  ).catch(err => err);
};