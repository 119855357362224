import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudUpdate, SaveButton } from 'react-admin';

const customUpdate = (resource, values, basePath, record, redirectTo) => {
  if (values.businessSegment === '') {
    values.businessSegment = null;
  }
  return crudUpdate(resource, record.id, values, record, basePath, redirectTo);
}

class CustomUpdateComponent extends Component {

  handleClick = () => {
    const { basePath, handleSubmit, redirectTo, record, customUpdate, resource } = this.props;

    return handleSubmit(values => {
      customUpdate(resource, values, basePath, record, redirectTo);
    });
  };

  render() {
    const { handleSubmitWithRedirect, customUpdate, ...props } = this.props;
    return (
      <SaveButton
        handleSubmitWithRedirect={this.handleClick}
        {...props}
      />
    );
  }
}

export const CustomUpdateButton = connect(
  undefined,
  { customUpdate }
)(CustomUpdateComponent);