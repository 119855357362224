import React from 'react';
import { Create, Datagrid, Edit, EditButton, List, Show, ShowButton, SimpleForm, SimpleShowLayout } from 'react-admin';
import { ListActions } from '../../components/List';
import CustomPagination from '../../components/Pagination';
import { fieldsToInputs, resourcesPropertiesToFields } from "../../helpers/fieldTransformer";

export const PartnerList = (props) => {
  const { options: { api, resource } } = props;
  const fields = resourcesPropertiesToFields(api, resource);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      pagination={<CustomPagination />}
      perPage={50} actions={<ListActions />}
    >
      <Datagrid>
      {fields.map(field =>
                    field
        )}
        <EditButton basePath="/partners" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const PartnerCreate = (props) => {
  const { options: { api, resource } } = props;

  const fields = fieldsToInputs(api, resource);

  return (
    <Create {...props}>
      <SimpleForm>
        {fields.map(field =>
          field
        )}
      </SimpleForm>
    </Create>
  )
};

export const PartnerEdit = (props) => {
  const { options: { api, resource } } = props;

  const fields = fieldsToInputs(api, resource);

  return (
    <Edit {...props}>
      <SimpleForm>
        {fields.map(field =>
          field
        )}
      </SimpleForm>
    </Edit>
  )
};

export const PartnerShow = (props) => {
  const { options: { api, resource } } = props;
  const fields = resourcesPropertiesToFields(api, resource);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        {fields.map(field =>
          field
        )}
      </SimpleShowLayout>
    </Show>
  )
};
