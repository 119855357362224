import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import { required, Toolbar, Create, Datagrid, Show, Edit, ReferenceField, FunctionField, SimpleShowLayout, EditButton, List, RichTextField, ShowButton, SimpleForm, ReferenceInput, SelectInput } from 'react-admin';
import { ListActions } from '../../components/List';
import CustomPagination from '../../components/Pagination';
import { fieldsToInputs, resourcesPropertiesToFields } from "../../helpers/fieldTransformer";
import { CustomUpdateButton } from '../../components/Button';

const RecommendationEditToolbar = (props) => {
  const { record, redirect, resource } = props;
  return (
    <Toolbar {...props} >
      <CustomUpdateButton record={record} redirectTo={redirect} resource={resource} />
    </Toolbar>
  );
}

export const RecommendationList = (props) => {
  const { options: { api, resource } } = props;
  const fields = resourcesPropertiesToFields(api, resource, ['value', 'businessSegment', 'chapter', 'position']);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      pagination={<CustomPagination />}
      perPage={50} actions={<ListActions />}
    >
      <Datagrid>
        <ReferenceField source="question" reference="questions">
          <FunctionField render={record => `Q${record.identifier} ${record.title}`} />
        </ReferenceField>
        <RichTextField source="text" stripTags />
        {fields.map(field =>
          field
        )}
        <EditButton basePath="/recommendations" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const RecommendationCreate = (props) => {
  const { options: { api, resource } } = props;

  const fields = fieldsToInputs(api, resource, ['value', 'businessSegment', 'position']);

  return (
    <Create {...props}>
      <SimpleForm>
        {fields.map(field =>
          field
        )}
        <ReferenceInput source="question" reference="questions" perPage={100}>
          <SelectInput optionText={(record) => {
            if (record.identifier) {
              return `Q${record.identifier} ${record.title}`;
            }
            return `${record.title}`;
          }} />
        </ReferenceInput>
        <ReferenceInput source="chapter" reference="recommendation_chapters">
          <SelectInput optionText={(record) => `${record.name}, position: ${record.position}`} />
        </ReferenceInput>
        <RichTextInput validate={[required()]} label="Description" source="text" toolbar={[['bold', 'italic', 'underline', 'link']]} />
      </SimpleForm>
    </Create>
  )
};

export const RecommendationEdit = (props) => {
  const { options: { api, resource } } = props;

  const fields = fieldsToInputs(api, resource, ['value', 'businessSegment', 'position']);

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<RecommendationEditToolbar />}>
        {fields.map(field =>
          field
        )}
        <ReferenceInput source="question" reference="questions" perPage={100}>
          <SelectInput optionText={(record) => {
            if (record.identifier) {
              return `Q${record.identifier} ${record.title}`;
            }
            return `${record.title}`;
          }} />
        </ReferenceInput>
        <ReferenceInput source="chapter" reference="recommendation_chapters">
          <SelectInput optionText={(record) => `${record.name}, position: ${record.position}`} />
        </ReferenceInput>
        <RichTextInput label="Description" source="text" toolbar={[['bold', 'italic', 'underline', 'link']]} />
      </SimpleForm>
    </Edit>
  )
};

export const RecommendationShow = (props) => {
  const { options: { api, resource } } = props;
  const fields = resourcesPropertiesToFields(api, resource, ['value', 'question', 'businessSegment', 'chapter', 'position']);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <RichTextField source="text" stripTags />
        {fields.map(field =>
          field
        )}
      </SimpleShowLayout>
    </Show>
  )
};
