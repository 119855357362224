import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/icons/AddCircle';

export const CreateButton = ({record, text}) => (
    <Button
        variant="contained"
        color="secondary"
        style={{marginTop: 20}}
        component={Link}
        to={{
            pathname: '/business_segment_variants/create',
            state: { record: { question: record.id } },
        }}>
        <Icon style={{marginRight: 5}}/>
        {text}
    </Button>
);
