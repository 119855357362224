import { inputFactory, fieldFactory } from '../factory';

export const fieldsToInputs = (
    api,
    resource,
    fieldsToShow = []
) => {
    return resource.writableFields
    .filter(field => {
        if(fieldsToShow.length === 0) return true;

        return fieldsToShow.includes(field.name)
    })
    .map(field =>
        inputFactory(field, {
            api,
            resource,
        }),
    );
};

export const resourcesPropertiesToFields = (
    api,
    resource,
    fieldsToShow = []
) => {
    if(typeof resource == 'undefined') return [];

    return resource.readableFields
    .filter(field => {
        if(fieldsToShow.length === 0) return true;

        return fieldsToShow.includes(field.name)
    })
    .map(field =>
        fieldFactory(field, {
            api,
            resource,
        }),
    );
};
