import React from 'react';
import Button from '@material-ui/core/Button';
import { DownloadButton } from './DownloadButton';

const API = process.env.REACT_APP_API_ENTRYPOINT;

export const GenerateReportButton = ({ surveySlug, children }) => {

  class Component extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        report: null
      };
    }

    handleAction = async () => {
      const fetchHeaders = { 'Authorization': `Bearer ${window.localStorage.getItem('token')}` };

      const result = await fetch(`${API}/reports${surveySlug}`, {
        headers: { ...fetchHeaders },
        method: 'POST'
      });

      const response = await result.json();

      this.setState({
        report: response.report
      })
    }

    render() {
      if (this.state.report) {
        return (
          <DownloadButton url={`${API}/reports/${this.state.report}`} filename={this.state.report} >
            Télécharger le rapport avancé
          </DownloadButton>
        )
      }
      return (
        <Button variant="contained" color="secondary" role="button" onClick={this.handleAction}>{children}</Button>
      )
    }
  }

  return <Component />;
}