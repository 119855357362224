import * as jwtDecode from 'jwt-decode';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';

const login_uri = `${process.env.REACT_APP_API_ENTRYPOINT}/login_check`;

export default (type, params) => {
  switch (type) {
    case AUTH_LOGIN:
      const { username, password } = params;
      const request = new Request(`${login_uri}`, {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });

      return fetch(request)
          .then(response => {
            if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);

            return response.json();
          })
          .then(({ token }) => {
            const { roles } = jwtDecode(token);
            if (!roles.includes('ROLE_ADMIN') && !roles.includes('ROLE_SUPER_ADMIN')) {
              throw new Error('You must be admin');
            }
            localStorage.setItem('token', token); // The JWT token is stored in the browser's local storage
            localStorage.setItem('roles', roles); // The JWT token roles are stored in the browser's local storage
            window.location.replace('/');
          });

    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('roles');
      break;

    case AUTH_ERROR:
      const { status } = params;

      if (401 === status || 403 === status) {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');

        return Promise.reject();
      }
      break;

    case AUTH_CHECK:
      return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();

    case AUTH_GET_PERMISSIONS:
      const roles = localStorage.getItem('roles');
      return roles ? Promise.resolve(roles.split(",")) : Promise.reject({ redirectTo: '/login' });

    default:
      return Promise.resolve();
  }
}
