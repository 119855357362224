import React from 'react';
import Button from '@material-ui/core/Button';

export const DownloadButton = ({ url, filename, children })  => {
  const handleAction = async () => {
    const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
  
    const result = await fetch(url, {	
      headers: {...fetchHeaders}
    })
    
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = href;
    link.download = filename
    link.setAttribute('type', 'hidden');

    link.click()
  }

  return (
    <>
      <Button variant="contained" color="secondary" role="button" onClick={handleAction}>{children}</Button>
    </>
  )
}