import React from 'react';
import { Redirect } from 'react-router-dom';
import { Admin, Resource } from 'react-admin';
import { hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';

import authProvider from './authProvider';
import {inputFactory, fieldFactory} from './factory';
import {CategoryCreate, CategoryEdit, CategoryList, CategoryShow} from './resources/Category';
import {QuestionCreate, QuestionEdit, QuestionList, QuestionShow} from './resources/Question';
import {SurveyCreate, SurveyEdit, SurveyList, SurveyShow} from './resources/Survey';
import {isSuperAdmin} from "./helpers/permissions";
import { i18nProvider } from './i18nProvider';
import {
  BusinessSegmentVariantCreate,
  BusinessSegmentVariantEdit,
  BusinessSegmentVariantList
} from './resources/BusinessSegmentVariant';
import {
  BusinessSegmentList,
  BusinessSegmentCreate,
  BusinessSegmentEdit
} from './resources/BusinessSegment';
import {
  RecommendationList,
  RecommendationCreate,
  RecommendationEdit,
  RecommendationShow
} from './resources/Recommendation';
import {
  RecommendationChapterList,
  RecommendationChapterCreate,
  RecommendationChapterEdit,
  RecommendationChapterShow
} from './resources/RecommendationChapter';
import {
  PartnerList,
  PartnerCreate,
  PartnerEdit,
  PartnerShow
} from './resources/Partner';
import {
  ReportPointList,
  ReportPointCreate,
  ReportPointEdit,
  ReportPointShow
} from './resources/ReportPoint';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: new Headers(fetchHeaders),
});
const dataProvider = api => hydraClient(api, fetchHydra);
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({ api }),
        (result) => {
          switch (result.status) {
            case 401:
              return Promise.resolve({
                api: {...result.api, resources: []},
                customRoutes: [{
                  props: {
                    path: '/',
                    render: () => <Redirect to={`/login`}/>,
                  },
                }],
              });
            default:
              return Promise.reject(result);
          }
        },
    );


export default class extends React.Component {
  state = {api: null, resources: null, customRoutes: []};

  componentDidMount() {
    apiDocumentationParser(entrypoint)
    .then(({api, customRoutes = []}) => {
      let resources = api.resources;
      this.setState({api, resources, customRoutes});
    })
  }

  render() {
    if (null === this.state.api) return <div>Loading...</div>;

    return (
        <Admin
            apiDocumentationParser={apiDocumentationParser}
            authProvider={authProvider}
            entrypoint={entrypoint}
            dataProvider={dataProvider(this.state.api, fetchHydra)}
            locale="fr"
            i18nProvider={i18nProvider}
            customRoutes={this.state.customRoutes}
        >
          {permissions => [
            <Resource
              name="surveys"
              title="Surveys"
              list={SurveyList}
              create={isSuperAdmin(permissions) ? SurveyCreate : null}
              edit={isSuperAdmin(permissions) ? SurveyEdit : null}
              show={SurveyShow}
              options={{
                inputFactory,
                fieldFactory,
                api: this.state.api,
                resources: this.state.resources,
                resource:  this.state.resources.find(({ name }) => 'surveys' === name)
              }}
            />,
            <Resource
                name="categories"
                title="Categories"
                list={CategoryList}
                create={isSuperAdmin(permissions) ? CategoryCreate : null}
                edit={CategoryEdit}
                show={CategoryShow}
                options={{
                  inputFactory,
                  fieldFactory,
                  api: this.state.api,
                  resources: this.state.resources,
                  resource:  this.state.resources.find(({ name }) => 'categories' === name)
                }}
            />,
            <Resource
                name="questions"
                title="Questions"
                list={QuestionList}
                create={isSuperAdmin(permissions) ? QuestionCreate:null}
                edit={QuestionEdit}
                show={QuestionShow}
                options={{
                  inputFactory,
                  fieldFactory,
                  api: this.state.api,
                  resources: this.state.resources,
                  resource:  this.state.resources.find(({ name }) => 'questions' === name)
                }}
            />,
            <Resource
                name="answers"
                title="Answers"
            />,
            <Resource
                name="criterias"
                title="Criteria"
            />,
            <Resource
                name="question_criterias"
                title="QuestionCriteria"
            />,
            <Resource
              name="report_points"
              title="ReportPoints"
              list={ReportPointList}
              create={ReportPointCreate}
              edit={ReportPointEdit}
              show={ReportPointShow}
              options={{
                inputFactory,
                fieldFactory,
                api: this.state.api,
                resources: this.state.resources,
                resource: this.state.resources.find(({ name }) => 'report_points' === name)
              }}
            />,
            <Resource
                name="recommendation_chapters"
                title="RecommendationChapters"
                list={RecommendationChapterList}
                create={RecommendationChapterCreate}
                edit={RecommendationChapterEdit}
                show={RecommendationChapterShow}
                options={{
                  inputFactory,
                  fieldFactory,
                  api: this.state.api,
                  resources: this.state.resources,
                  resource:  this.state.resources.find(({ name }) => 'recommendation_chapters' === name)
                }}
            />,
            <Resource
                name="recommendations"
                list={RecommendationList}
                create={RecommendationCreate}
                edit={RecommendationEdit}
                title="Recommendations"
                show={RecommendationShow}
                options={{
                  inputFactory,
                  fieldFactory,
                  api: this.state.api,
                  resources: this.state.resources,
                  resource:  this.state.resources.find(({ name }) => 'recommendations' === name)
                }}
            />,
            <Resource
              name="partners"
              title="Partenaires"
              list={PartnerList}
              create={PartnerCreate}
              edit={PartnerEdit}
              show={PartnerShow}
              options={{
                inputFactory,
                fieldFactory,
                api: this.state.api,
                resources: this.state.resources,
                resource: this.state.resources.find(({ name }) => 'partners' === name)
              }}
            />,
            isSuperAdmin(permissions)
                ? <Resource name="users" title="Users"/>
                : null,
            <Resource 
              name="business_segments" 
              title="Business Segments"
              list={BusinessSegmentList}
              create={BusinessSegmentCreate}
              edit={BusinessSegmentEdit}
              options={{
                inputFactory,
                fieldFactory,
                api: this.state.api,
                resources: this.state.resources,
                resource:  this.state.resources.find(({ name }) => 'business_segments' === name)
              }}/>,
            <Resource
              name="business_segment_variants"
              title="Business Segment Variants"
              list={isSuperAdmin(permissions) ? BusinessSegmentVariantList : null}
              create={BusinessSegmentVariantCreate}
              edit={BusinessSegmentVariantEdit}
              options={{
                inputFactory,
                fieldFactory,
                api: this.state.api,
                resources: this.state.resources,
                resource:  this.state.resources.find(({ name }) => 'business_segment_variants' === name)
              }}/>,
          ]}
        </Admin>
    )
  }
}
