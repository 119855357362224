import React from 'react';
import { Create, SimpleForm, ShowButton, EditButton } from 'react-admin';
import { List, Datagrid, TextField, Edit, Show, ReferenceField, NumberField, FunctionField, downloadCSV } from 'react-admin';
import { TabbedShowLayout, Tab, ReferenceManyField } from 'react-admin';
import jsonExport from 'jsonexport/dist';

import { fieldsToInputs, resourcesPropertiesToFields } from "../../helpers/fieldTransformer";
import { isSuperAdmin } from "../../helpers/permissions";
import CustomPagination from '../../components/Pagination';
import { ListActions } from '../../components/List';
import { DownloadButton, GenerateReportButton } from '../../components/Button';
import { apiCommunication } from '../../utils/api';

const API = process.env.REACT_APP_API_ENTRYPOINT;

const ReportLinkField = ({ record = {} }) => {
  if (record.report) {
    return (
      <DownloadButton url={`${API}/reports/${record.report}`} filename={record.report} >
        Télécharger le rapport avancé
      </DownloadButton>
    );
  }
  else {
    return (
      <GenerateReportButton surveySlug={record.id}>
        Générer le rapport avancé
      </GenerateReportButton>
    )
  }
}

const exporter = async (surveys, fetchRelatedRecords,) => {
  const businessSegments = await fetchRelatedRecords(surveys, 'businessSegment', 'business_segments');
  // TODO : should improve this
  let criterias = await apiCommunication('GET', '/criterias');
  criterias = criterias['hydra:member'].reduce((acc, value) => {
    const id = value['@id'].split(('/')).pop();
    acc[id] = value.radarLabel;
    return acc;
  }, {});

  const data = surveys.map((survey) => {
    const { id, type, answers, lock, originId, '@id': objectId, '@type': objectType, report, businessSegment, scoreByCriteria, ...fieldForExport } = survey;
    fieldForExport['Secteur d\'activité'] = businessSegments[survey.businessSegment].name
    if (scoreByCriteria) {
      for (const criteraId in criterias) {
        fieldForExport[criterias[criteraId]] = (+scoreByCriteria[criteraId]).toFixed(2);
      }
    }
    return fieldForExport;
  });

  jsonExport(data, {
    headers: ['Secteur d\'activité', 'email', 'code', 'createdAt', 'updatedAt', 'acceptabilityScore'],
    rename: ['Secteur d\'activité', 'Email', 'Code', 'Date de création', 'Date de modification', 'Note d\'acceptabilité']
  }, (err, csv) => {
    downloadCSV(csv, `export_questionnaires_${new Date().toLocaleDateString('fr-FR')}`);
  });
}

export const SurveyList = (props) => {
  const { options: { api, resource } } = props;
  const fields = resourcesPropertiesToFields(api, resource, ['businessSegment', 'email', 'lock', 'updatedAt', 'code']);

  return (
    <List {...props} exporter={exporter} bulkActionButtons={false} pagination={<CustomPagination />} perPage={50} actions={<ListActions />}>
      <Datagrid>
        {fields.map(field =>
          field
        )}
        <NumberField source="acceptabilityScore" />
        {isSuperAdmin(props.permissions) && <EditButton basePath="/surveys" />}
        <ShowButton />
      </Datagrid>
    </List>
  );
};
export const SurveyCreate = (props) => {
  const { options: { api, resource } } = props;
  const fields = fieldsToInputs(api, resource);

  return (
    <Create {...props}>
      <SimpleForm>
        {fields.map(field =>
          field
        )}
      </SimpleForm>
    </Create>
  )
};

export const SurveyEdit = (props) => {
  const { options: { api, resource } } = props;
  const fields = fieldsToInputs(api, resource);

  return (
    <Edit {...props}>
      <SimpleForm>
        {fields.map(field =>
          field
        )}
      </SimpleForm>
    </Edit>
  )
};

export const SurveyShow = (props) => (
  <Show {...props} title={"Questionnaire"}>
    <TabbedShowLayout>
      <Tab label="app.tab.summary">
        {isSuperAdmin(props) && <TextField label="Id" source="id" />}
        {resourcesPropertiesToFields(
          props.options.api,
          props.options.resource,
          ['businessSegment', 'email', 'lock', 'code']
        ).map(field => field)}
        <NumberField source="acceptabilityScore" />
        <ReportLinkField source="report" />
      </Tab>
      <Tab label='app.resources.answers'>
        <ReferenceManyField target="survey" reference="answers" addLabel={false}>
          <Datagrid>
            {resourcesPropertiesToFields(
              props.options.api,
              props.options.api.resources.find(resource => resource.name === "answers"),
              ['value']
            ).map(field => field)}
            <ReferenceField source="question" reference="questions" sortBy="identifier">
              <FunctionField render={record => `Q${record.identifier} ${record.title}`} />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
