module.exports = {
  ra: {
    boolean: {
      true: 'Oui',
      false: 'Non'
    },
  },
  resources: {
    report_points: {
      name: 'Point du rapport |||| Points du rapport'
    },
    recommendation_chapters: {
      name: 'Chapitre |||| Chapitres',
    },
    partners: {
      name: 'Partenaire |||| Partenaires',
      fields: {
        disabled: "Désactiver"
      }
    },
    surveys: {
      name: 'Réponse questionnaire |||| Réponses questionnaires',
      fields: {
        businessSegment: "Secteur d'activité",
        email: "Email",
        lock: "Terminé ?",
        acceptabilityScore: "Note d'acceptabilité",
        updatedAt: "Mise à jour",
      },
    },
    categories: {
      name: 'Catégorie |||| Categories',
      fields: {
        name: "Nom",
      },
    },
    questions: {
      name: 'Question |||| Questions',
      fields: {
        title: "Titre",
        category: "Catégorie",
      },
    },
    business_segment_variants: {
      name: 'Variante de question |||| Variantes',
      fields: {
        name: "Nouvelle question",
        businessSegment: "Secteur d'activité",
      },
    },
    business_segments: {
      name: "Secteur d'activité |||| Secteurs d'activité",
      fields: {
      },
    },
    answers: {
      name: 'Réponse ||||| Réponses',
      fields: {
        value: "Valeur de la réponse",
      },
    },
    question_criterias: {
      name: 'Valeurs de sortie',
      fields: {
        value: "Valeur pour la sortie",
        responseType: "Type de réponse",
        criteria: "Sortie",
      },
    },
  },
  app: {
    resources: {
      answers: "Réponses",
      criteria_values: "Valeurs de sorties",
      business_segment_variants: "Variantes",
    },
    business_segment: {
      biogas: "Biogaz",
      eolian: "Eolien",
      quarry: "Carrière",
    },
    tab: {
      summary: "Résumé",
    },
  },
  question: 'Question de référence',
};
