import React from 'react';
import { List, Show, Datagrid, Edit, Create, SimpleForm, ShowButton, SaveButton, EditButton, Toolbar} from 'react-admin';
import { TabbedShowLayout, Tab, TabbedForm, FormTab } from 'react-admin';
import { TextField, ReferenceManyField, ReferenceField, FunctionField } from 'react-admin';
import {fieldsToInputs, resourcesPropertiesToFields} from "../../helpers/fieldTransformer";
import { CreateButton } from '../../components/Button';
import CustomPagination from '../../components/Pagination';
import { ListActions } from '../../components/List';

const QuestionTitle = ({ record }) => {
  return <span>Question {record ? `"${record.title}"` : ''}</span>;
};

const QuestionEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const QuestionList = (props) => {
    const {options: {api, resource }} = props;
    const fields = resourcesPropertiesToFields(api, resource,['category']);

    return (
        <List
          {...props}
          bulkActionButtons={false}
          exporter={false}
          actions={<ListActions />}
          pagination={<CustomPagination/>}
          sort={{ field: 'category', order: 'DESC' }}
          perPage={50}>
            <Datagrid>
                <FunctionField render={record => `Q${record.identifier} ${record.title}`} />
                {fields.map(field =>
                    field
                )}
                 <EditButton basePath="/questions" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

const CreateRelatedBusinessSegmentTranslationButton = ({record}) => (
  <CreateButton record={record} text="Décliner pour un segment"/>
);

export const QuestionCreate = (props) => {
    const {options: {api, resource }} = props;

    const fields = fieldsToInputs(api, resource);

    return (
        <Create { ...props }>
            <SimpleForm>
                {fields.map(field =>
                    field
                )}
            </SimpleForm>
        </Create>
    )
};

export const QuestionEdit = (props) => {
    const {options: {api, resource }} = props;

    const fields = fieldsToInputs(api, resource,['title', 'category', 'identifier']);

    return (
        <Edit { ...props } title={<QuestionTitle />}>
            <TabbedForm toolbar={<QuestionEditToolbar/>}>
                <FormTab label="app.tab.summary">
                    {fields.map(field =>
                        React.cloneElement(field, {
                            fullWidth: true,
                        })
                    )}
                </FormTab>
                <FormTab label="app.resources.criteria_values">
                    <ReferenceManyField target="question" reference="question_criterias" addLabel={false}>
                        <Datagrid>
                            {resourcesPropertiesToFields(
                              props.options.api,
                              props.options.api.resources.find(resource => resource.name === "question_criterias"),
                              ['value']
                            ).map(field => field)}
                            <FunctionField
                              label="resources.question_criterias.fields.responseType"
                              render={record => `${record.responseType === 'yes' ? 'Oui': 'Non'}`} />
                            <ReferenceField source="criteria" reference="criterias">
                                <TextField source="name" />
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab label="app.resources.business_segment_variants">
                  <ReferenceManyField target="question" reference="business_segment_variants" addLabel={false} { ...props }>
                    <Datagrid>
                      <TextField source="name" />
                      <ReferenceField source="businessSegment" reference="business_segments">
                        <TextField source="name" />
                      </ReferenceField>
                      <EditButton basePath="/business_segment_variants" />
                    </Datagrid>
                  </ReferenceManyField>
                  <CreateRelatedBusinessSegmentTranslationButton />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export const QuestionShow = (props) => {
    const {options: {api, resource }} = props;
    const fields = resourcesPropertiesToFields(api, resource, ['title', 'category', 'identifier']);

    return (
        <Show { ...props } title={<QuestionTitle />}>
            <TabbedShowLayout>
                <Tab label="app.tab.summary">
                    {fields.map(field =>
                        field
                    )}
                </Tab>
                <Tab label="app.resources.criteria_values">
                    <ReferenceManyField target="question" reference="question_criterias" addLabel={false}>
                        <Datagrid>
                            {resourcesPropertiesToFields(
                              props.options.api,
                              props.options.api.resources.find(resource => resource.name === "question_criterias"),
                              ['value']
                            ).map(field => field)}
                            <FunctionField
                              label="resources.question_criterias.fields.responseType"
                              render={record => `${record.responseType === 'yes' ? 'Oui': 'Non'}`} />
                            <ReferenceField source="criteria" reference="criterias">
                                <TextField source="name" />
                            </ReferenceField>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
              <Tab label="app.resources.business_segment_variants">
                <ReferenceManyField target="question" reference="business_segment_variants" addLabel={false} { ...props }>
                  <Datagrid>
                    <TextField source="name" />
                    <ReferenceField source="businessSegment" reference="business_segments">
                      <TextField source="name" />
                    </ReferenceField>
                    <EditButton basePath="/business_segment_variants" />
                  </Datagrid>
                </ReferenceManyField>
                <CreateRelatedBusinessSegmentTranslationButton />
              </Tab>
            </TabbedShowLayout>
        </Show>
    )
};
